import { axiosInstance } from '@/utils/AxiosInstance';

import { FakeCustomerId, FakePhoneNumber } from '@/constants';
import { _afLogEvent } from './af';
import { _amplitudeLogPurchase } from './amplitude';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const gtag: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const AF: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const amplitude: any;

const CURRENCY = 'KZT';

export enum TAddressType {
  PICKUP = 'pickup',
  DROPOFF = 'dropoff',
}

export enum TAddressSource {
  AUTOCOMPLETE = 'autocomplete',
  MAP = 'map',
}

export enum TSubscriptionType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  MONTH_SUB = 'month_sub',
  PREPAYMENT = 'prepayment'
}

export function tSetFunnelVersion() {
  const identify = new amplitude.Identify();
  identify.set('funnel-version', __APP_VERSION__);
  amplitude.identify(identify);
}

export function tSetCustomerId(cuid: number) {
  if (cuid === FakeCustomerId) return;
  gtag('set', 'client_id', cuid.toString());
  AF('pba', 'setCustomerUserId', cuid.toString());
  amplitude.setUserId(cuid.toString());
  
  tSetFunnelVersion();
}

interface CustomerData {
  id: number;
}

export function tSetCustomerIdByPhone(phone: string, firstName: string = '', lastName: string = '') {
  if (phone === FakePhoneNumber) return;

  axiosInstance
    .post<CustomerData>('/customers', { phone, first_name: firstName, last_name: lastName })
    .then((response) => {
      tSetCustomerId(response.id);
    });
}

export function tLogPageView() {
  // TODO: Add saving referrer as well.
  _afLogEvent('af_page_view', { location: window.location.href });
}

export function tLogCompleteRegistration(cuid: number) {
  if (cuid === FakeCustomerId) return;
  tSetCustomerId(cuid);
  gtag('event', 'login', { method: 'phone' });
  fbq('track', 'Lead');
  _afLogEvent('af_complete_registration', { method: 'phone' });
  amplitude.track('complete_registration', { method: 'phone' });
}

export function tLogStartPurchase(amount: number) {
  gtag('event', 'begin_checkout', { currency: CURRENCY, value: amount });
  fbq('track', 'InitiateCheckout', { currency: CURRENCY, value: amount });
  _afLogEvent('af_start_purchase', { af_revenue: amount, af_currency: CURRENCY }, amount, CURRENCY);
  amplitude.track('begin_checkout', { currency: CURRENCY, value: amount });
}

export function tLogPurchase(amount: number) {
  gtag('event', 'purchase', { currency: CURRENCY, value: amount });
  fbq('track', 'Purchase', { currency: CURRENCY, value: amount });
  _afLogEvent('af_purchase', { af_revenue: amount, af_currency: CURRENCY }, amount, CURRENCY);
  _amplitudeLogPurchase(amount);
}

export function tLogCustomEvent(name: string, data: object = {}) {
  gtag('event', name, data);
  _afLogEvent(`af_${name}`, data);
  amplitude.track(name, data);
}
