import { AppDispatch, RootState } from '@/projects/shuttle/store/store';
import { showToasterWithDelay } from '@/store/slices/toasterSlice';
import { axiosInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TransportationRequestKind } from '@/shuttle/constants';

type transportationRequestCreateData = {
  phone: string;
  kind: string;
};

export type transportationRequestCreateResponse = {
  id: number
};

export const createTransportationRequestAsync = createAsyncThunk<
transportationRequestCreateResponse,
transportationRequestCreateData,
{
  dispatch: AppDispatch;
  rejectValue: string;
}
>(
  'transportationRequest/create',
  async (data: transportationRequestCreateData, { dispatch, rejectWithValue }) => {
    return await axiosInstance
      .post<transportationRequestCreateResponse>('/transportation-requests', data)
      .then((data): transportationRequestCreateResponse => data)
      .catch(() => {
        dispatch(
          showToasterWithDelay({
            type: 'warning',
            message: 'Ошибка при отправке данных',
            isVisible: true,
          })
        );
        return rejectWithValue('Ошибка при отправке данныех transporationRequest');
      });
  }
);


interface TransportationRequestState {
  transportationRequestKind: TransportationRequestKind,
  transportationRequestId: number | null
}

const initialState: TransportationRequestState = {
  transportationRequestKind: TransportationRequestKind.TO_WORK,
  transportationRequestId: null,
};

export const transportationRequestSlice = createSlice({
  name: 'transportationRequest',
  initialState,
  reducers: {
    setTransportationRequestKind: (state, action) => {
      state.transportationRequestKind = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTransportationRequestAsync.fulfilled, (state, action) => {
      const response = action.payload as transportationRequestCreateResponse;
      state.transportationRequestId = response.id;
      }
    );
    builder.addCase(createTransportationRequestAsync.rejected, () => {});
  },
});

export const { setTransportationRequestKind } = transportationRequestSlice.actions;

export const transportationRequestSelector = (state: RootState) => state.transportationRequestReducer;

export default transportationRequestSlice.reducer;
